import React from 'react';
import './App.css';
import {Home} from "./pages/Home";

function App() {
  return (
      <div className="flex flex-col h-full w-100vw">
          <div className={"flex flex-grow"}>
              <div className={"flex-grow"}>
                  <Home></Home>
              </div>
          </div>
      </div>
    )
  ;
}

export default App;
