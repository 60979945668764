import React, {useEffect, useState} from "react";
import axios from "axios";
import ReactLoading from 'react-loading';
import oceane1 from "../images/oceane1.jpg"
import oceane2 from "../images/oceane2.jpg"
import oceane3 from "../images/oceane3.jpg"
import oceane4 from "../images/oceane4.jpg"
import ofLogo from "../images/onlyfanslogo.png"
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
    {
        original: oceane1,
    },
    {
        original: oceane2,
    },
    {
        original: oceane3,
    },
    {
        original: oceane4,
    },
];

export function Home() {
    const [loading, setLoading] = useState(true)
    const [ip, setIp] = useState(null)
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')

    useEffect(() => {
        axios.get("https://api.ipify.org/?format=json").then((res) => {
            setIp(res.data.ip)
        })
    }, [])

    useEffect(() => {
        if (!ip) return
        axios.get("https://freeipapi.com/api/json/" + ip).then((res) => {
            setCity(res.data.cityName)
            setCountry(res.data.countryName)
            setLoading(false)
        })
    }, [ip]);

    return <>
        <div className={"sticky z-10 bg-white top-0 w-full h-14 border-b p-1 flex justify-center"}>
            <img src={ofLogo} alt={"oflogo"} className={"h-11"}/>
        </div>
        {
            loading ? <div className={"flex w-full justify-center"}>
                    <ReactLoading color={"#000"}/>
                </div>
                :
                <div className={"flex flex-col items-center gap-10 px-5"}>
                    <div className={"mt-5 md:mt-10 -mb-5 md:-mb-0"}>
                        <ImageGallery
                            showFullscreenButton={false}
                            showThumbnails={false}
                            showNav={false}
                            showPlayButton={false}
                            autoPlay={true}
                            items={images}/>
                    </div>
                    <div className="center-align grey-text text-darken-3">
                        <ul className="">
                            <li>
                                <p className="grey-text center-align text-darken-3">
                                    🎁 50% OFF SALE 🎁 LoveOceane
                                </p>
                            </li>
                            <li>
                                <p>
                                    📍 <span className="location">{city + ", " + country}</span>
                                </p>
                                <p>
                                    🧁 21 years ♒ Aquarius
                                </p>
                                <p>
                                    🟢 Online Now
                                </p>
                            </li>
                            <li>
                                <p>
                                    🍑💦 Do you live near
                                    <b> <span id="city" className={"font-bold"}>{city}</span></b>
                                    ?
                                </p>
                                <p>
                                    📍 DM me on OF after
                                    subscribing about making content :) 🍑🔥
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="center">
                        <a href="https://onlyfans.com/loveoceane" className={"ofLink font-bold text-xl"}>
                            SEE MY ONLYFANS ❤️
                        </a>
                        <p className="mt-5 font-weight-bold text-center ofTextColor">
                            Get access to my private account now! Only $5.00/m limited time
                        </p>
                    </div>
                </div>
        }
    </>
}
